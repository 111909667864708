import './contact-bar.scss';

class ContactBar {
    constructor (element, options) {
        // Default options
        const defaults = {
            initAttr: 'data-contactbar',
            closeAttr: 'close'
        };

        // Settings
        this.settings = Object.assign({}, defaults, options);
        this.active = false;
        this.mutationObserver = null;
        this.zendeskBadge = false;
        this.zendeskInfo = false;

        // Elements
        this.$contactBar = element;
        this.$closeButton = this.$contactBar.querySelector(`[${this.settings.initAttr}="${this.settings.closeAttr}"]`);

        if (this.$contactBar && this.$closeButton) {
            this.initialize();
        }
    }

    initialize () {
        this.observeZendeskBadge();

        if (window.innerWidth >= 768) {
            this.toggleState();
        }

        this.setEvents();
    }

    observeZendeskBadge () {
        const $chatInfo = document.querySelector('iframe[title="Nachricht vom Unternehmen"]');
        const $zendeskBadge = document.querySelector('#launcher');

        if ($chatInfo) {
            this.zendeskInfo = true;
        }

        if ($zendeskBadge) {
            this.zendeskBadge = true;
        }

        if (this.zendeskBadge || this.zendeskInfo) { this.positionBadge(); }

        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.type === 'childList') {
                    mutation.addedNodes.forEach(node => {
                        const $chatInfo = document.querySelector('iframe[title="Nachricht vom Unternehmen"]');

                        if ($chatInfo) {
                            this.zendeskInfo = true;
                        }

                        if (node.id === 'launcher') {
                            this.zendeskBadge = true;
                        }

                        if (this.zendeskBadge || this.zendeskInfo) { this.positionBadge(); }
                    });

                    mutation.removedNodes.forEach(node => {
                        const $chatInfo = document.querySelector('iframe[title="Nachricht vom Unternehmen"]');
                        const $zendeskBadge = document.querySelector('#launcher');

                        if (!$chatInfo) {
                            this.zendeskInfo = false;
                        }

                        if (!$zendeskBadge) {
                            this.zendeskBadge = false;
                        }

                        if (!this.zendeskBadge || !this.zendeskInfo) { this.positionBadge(); }
                    });
                }
            });
        });

        const config = { childList: true, subtree: true };

        observer.observe(document.body, config);
    }

    positionBadge () {
        if (this.zendeskBadge) {
            this.$contactBar.classList.add('contact-bar--offset');
        } else {
            this.$contactBar.classList.remove('contact-bar--offset');
        }

        if (this.zendeskInfo) {
            this.$contactBar.classList.add('contact-bar--offset-2');
        } else {
            this.$contactBar.classList.remove('contact-bar--offset-2');
        }
    }

    toggleState () {
        if (!this.active) {
            this.$contactBar.classList.add('contact-bar--active');
            this.active = true;
        } else {
            this.$contactBar.classList.remove('contact-bar--active');
            this.active = false;
        }
    }

    setEvents () {
        this.$contactBar.addEventListener('click', (e) => {
            if (!this.active && e.target !== this.$closeButton) {
                this.toggleState();
            }
        });

        this.$closeButton.addEventListener('click', () => {
            if (this.active) {
                this.toggleState();
            }
        });
    }
}

export default ContactBar;

/**
 * usage
 * import { MediaMatchHandler } from './utilities/js/media-match-handler';
 *
 */

class MediaMatchHandler {
    constructor () {
        // this object can be extended if more breakpoint sets are needed
        this.mediaQueries = {
            320: '(min-width: 320px)',
            375: '(min-width: 375px)',
            480: '(min-width: 480px)',
            600: '(min-width: 600px)',
            768: '(min-width: 768px)',
            1024: '(min-width: 1024px)',
            1280: '(min-width: 1280px)',
            1440: '(min-width: 1440px)',
            1920: '(min-width: 1920px)'
        };

        this.matches = [];
        this.addMediaMatch();
    }

    addMediaMatch () {
        // iterate through different breakpoint sets
        Object.keys(this.mediaQueries).forEach((name) => {
            this[name] = window.matchMedia(this.mediaQueries[name]);
        });
    }
}

export { MediaMatchHandler };
